import React from "react";
import { Card, Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './App.css';

function App() {
  return (
    <div>
      <main>
        <Container>
          <Row xs={12}>
            <Col>
              <h1 className="mt-4">AUSPOL INTEL</h1>
            </Col>
          </Row>
          <hr />
          <Row className="gy-3" xs={1} md={2}>
            <Col>
              <Link to="/jordies" style={{ textDecoration: 'none', color: '#212529' }}>
                <Card className="card">
                  <Card.Body>
                    <Card.Title>FJ Court Cases &rarr; </Card.Title>
                    <Card.Text>
                      Keep up to date with FriendlyJordies' court cases.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col>
              <Link to="/trends" style={{ textDecoration: 'none', color: '#212529' }}>
                <Card className="card">
                  <Card.Body>
                    <Card.Title>Auspol Twitter Trends &rarr; </Card.Title>
                    <Card.Text>
                      Look at the lines go up and down! wow so cool! epic.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>
      </Container>
    </main>
    </div>
  );
}

export default App;
