import React from "react";
import { useEffect, useState } from 'react';
import { Row, Spinner, Table } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './App.css';
import moment from "moment";

function Trends() {
  const [data, setData] = useState(null)
  useEffect(() => {
    axios.get('/api/trends').then((res) => {
        setData(res.data)
    })
  }, [])
  return (
    <div>
        <main>
            <Container>
                <Row>
                <Col>
                    <h1 className="mt-4">Auspol Twitter Trends</h1>
                </Col>
                </Row>
                <hr />
                <Row>
                { (data == null) && <Col><div className="text-center"><Spinner animation="border" /></div></Col> }
                {
                    (data != null) &&
                    data.map(d => (
                        <Col md={12} lg={6}>
                            <h2>{d.name}</h2>
                            <ResponsiveContainer width="99%" aspect={2}>
                                <LineChart
                                width={500}
                                height={300}
                                data={d.data.data.map(k => ({ ...k, start: moment(k.start).format('YYYY-MM-DD hh:mm:ss')}))}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                                >
                                <CartesianGrid />
                                    <XAxis dataKey="start" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line dot={false} type="monotone" dataKey="tweet_count" stroke="#0070f3" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Col>
                    ))
                }
                </Row>
            </Container>
        </main>
    </div>
  );
}

export default Trends;
