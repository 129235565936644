import React from "react";
import { useEffect, useState } from 'react';
import { Row, Spinner, Table } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import './App.css';

function Jordies() {
  const [data, setData] = useState(null)
  useEffect(() => {
    axios.get('/api/court').then((res) => {
        setData(res.data)
    })
  }, [])
  return (
    <div>
      <main>
        <Container>
        <Row>
          <Col>
            <h1 className="mt-4">FJ Court Cases</h1>
          </Col>
        </Row>
        <hr />
        <Row>
            <Col>
          <h2>Barilaro v Shanks-Markovina</h2>
          {
            (data == null) ? <div className="text-center"><Spinner animation="border" /></div> :
          <Accordion>
          <Accordion.Item eventKey="0">
              <Accordion.Header>Events</Accordion.Header>
              <Accordion.Body>
              <Table responsive striped bordered hover>
                  <thead>
                      <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Location</th>
                      <th>Reason</th>
                      <th>Outcome</th>
                      <th>Officers</th>
                      </tr>
                  </thead>
                  <tbody>
                  {
                      data.bruz.events.map((d) => <tr>
                              <td>{d.date}</td>
                              <td>{d.time}</td>
                              <td>{d.location}</td>
                              <td>{d.reason}</td>
                              <td>{d.outcome}</td>
                              <td>{d.officers}</td>
                          </tr>
                      )
                  }
                  </tbody>
              </Table>
              </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
              <Accordion.Header>Documents</Accordion.Header>
              <Accordion.Body>
              <Table responsive striped bordered hover>
                  <thead>
                      <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Title</th>
                      <th>Filer</th>
                      </tr>
                  </thead>
                  <tbody>
                  {
                      data.bruz.docs.map((d) => <tr>
                              <td>{d.date}</td>
                              <td>{d.time}</td>
                              <td>{d.title}</td>
                              <td>{d.filer}</td>
                          </tr>
                      )
                  }
                  </tbody>
              </Table>
              </Accordion.Body>
          </Accordion.Item>
          </Accordion>
          }
          <h2 className="mt-4">R v Kristo Langker</h2>
          {
            (data == null) ? <div className="text-center"><Spinner animation="border" /></div> :
          <Accordion>
          <Accordion.Item eventKey="0">
              <Accordion.Header>Events</Accordion.Header>
              <Accordion.Body>
              <Table responsive striped bordered hover>
                  <thead>
                      <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Location</th>
                      <th>Room</th>
                      <th>Type</th>
                      <th>Officers</th>
                      </tr>
                  </thead>
                  <tbody>
                  {
                      data.fpiu.hits.map((d) => <tr>
                              <td>{d.scm_dateyear}</td>
                              <td>{d.time_listed}</td>
                              <td>{d.location}</td>
                              <td>{d.court_room_name}</td>
                              <td>{d.jl_listing_type_ds}</td>
                              <td>{d['officers.display_name']}</td>
                          </tr>
                      )
                  }
                  </tbody>
              </Table>
              </Accordion.Body>
          </Accordion.Item>
          </Accordion>
          }
          </Col>
      </Row>
      <Row>
          <Col className="mt-2">Info provided courtesy of the feds and nsw courts and shit.</Col>
      </Row>
      </Container>
    </main>
    </div>
  );
}

export default Jordies;
